// vogel colors
// deep blue: #002d59;
// light blue: #2289f7;

/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@import './assets/scss/material-icons.scss';
@import './assets/scss/signika.scss';

@import "../node_modules/@ostabo/flag-icons/css/flag-icons.min.css";

.material-icons {
  font-family: "Material Icons" !important;
}
.mdc-checkbox {
  margin-top: -52px !important;
}
.mdc-list-item__primary-text {
  color: #002d59;
  font-family: "Signika" !important;
}

* {
  font-family: "Signika" !important;
}
body {
  margin: 0rem;
  overflow-x: hidden;
  font-size: 14px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}
.spacer-1 {
  flex: 1;
}
.button-1,button {
  outline: none;
  background: linear-gradient(45deg, #BE0000, #FF3C3C 100%);
  border: 0;
  color: #fff !important;
  opacity: 1 !important;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  border-radius: 30px;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Signika", sans-serif;
  letter-spacing: 1.9px;
    
  &:hover {
    box-shadow: 0 20px 38px rgba(0,0,0,.16) !important;
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
  }
}
h1 {
  font-size: 40px;
  margin: 8px 0;
  font-weight: 600;
}
h2 {
  margin: 0;
  color: #1b1b1b;
  font-size: 35px !important;
  font-weight: 500 !important;
}
h3 {
  margin: 0;
  color: #1b1b1b;
  font-size: 26px;
  font-weight: 300;
}
h4 {
  margin: 0;
  color: #002d59;
  font-size: 18px !important;
  font-weight: 500;
  line-height: 2em !important;
  margin: 0 0 .5em !important;
}
h5,h6 {
  margin: 8px 0;
  color: #002d59;
}
.refer-text,mat-form-field,.form,.start-select {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
  margin-bottom: 5px;
  .start-select {
    margin-top: 20px;
  }
}
.data-checkbox {
  margin: 12px 0;
  display: flex;
}
.language-align {
  display: flex;
  align-items: center;
  mat-icon {
    margin-right: 8px;
  }
  .mat-mdc-select-value {
    width: 65%;
  }
}
.language-select {
  max-width: 175px;
  padding: 8px;
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
  }
  // Manipulate background color on hover and focus
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }
  .mdc-text-field--filled{
    --mdc-filled-text-field-active-indicator-height: 0;
    --mdc-filled-text-field-focus-active-indicator-height: 0;
  }
  .mat-mdc-form-field-flex {
    position: relative;
    float: right;
    right: -24px;
  }
  color: #002d59;
  mat-label {
    color: #002d59;
  }
}
p {
  margin: 0;
  color: #1b1b1b;
  font-size: 20px;
    font-weight:300;
}

.button-2-position {
  margin: 16px 0;
}
.mat-select-pinny {
  max-height: 42.5px;
}

.mat-checkbox {
    font-weight:300;
    color:#1b1b1b;
}

a:not(.logo-element) {
  color: #2289f7;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #002d59;
  }
}
.ticket-submitted-text {
  text-align: center;
}
::-webkit-scrollbar {
  width: 7.5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}
::-webkit-scrollbar-thumb {
  background: grey;
  &:hover {
    background: #2289f7;
  }
}
.snack-bar-form {
  color: #fff;
  font-size: 1.1em;
}
.logo-align{
  text-align: center;
  width: 100%;
  height: 100%;
}
.logo {
  max-width: 360px;
  max-height: 120px;
}
.toolbar {
  display: flex;
}
.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 45px;
  color: #002d59;
  font-size: medium;
  margin: 32px 8px 8px 8px;
    font-size:16px;
    font-weight:300;
}
.content {
  display: flex;
  margin: 32px auto auto auto;
  padding: 0 16px;
  max-width: 960px;
  flex-direction: column;
  align-items: center;
}
.mobile-content{
  display: flex;    
  flex-direction: column;
  align-items: center;
}
/* Mobile Styles */
@media only screen and (max-width: 320px) {
  h1 {
    font-size: 18px;
  }
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  .footer{
    font-size: 11px;
  }
  .content {
    display: flex;
    margin: 8px auto;
  }
  .mobile-content{
    display: inline;
    align-items: left;
  }
  .mobile-magic{
    display: none;
  }
  .toolbar {
    display: flex;
  }
  .logo {
    margin: 12px 0 0 0;
    max-width: 240px;
  }
}
/* Mobile Styles */
@media only screen and (min-width: 321px) and (max-width: 414px) {
  h1 {
    font-size: 22.5px;
  }
  h3 {
    font-size: 16px;
    position: relative;
  }
  p {
    font-size: 14px;
  }
  .footer{
    font-size: 12px;    
  }
  .content {
    display: flex;
    margin: 8px auto;
  }
  .mobile-content{
    display: inline;
    align-items: left;
  }
  .mobile-magic{
    display: none;
  }
  .toolbar {
    display: flex;
  }
  .spacer,
  .language-label {
    display: none;
  }
  .logo {
    max-width: 260px;
    margin: 12px 0 0 0;
  }
}
/* Tablet Styles */
@media only screen and (min-width: 415px) and (max-width: 978px) {
  h1 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
  .footer{
    font-size: 13px;
  }
  .content {
    display: flex;
    margin: 8px auto;
  }
}
/* footer resp style */
@media only screen and (max-height: 440px) {
  .footer{
    position: relative !important;
  }
}